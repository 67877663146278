<template>
<v-row class="pt-16 full-width mx-0" justify="center" align="start">
    <v-col xl="3" lg="4" md="8" sm="10" xs="12">
      <ValidationObserver ref="formObserver" v-slot="{valid}">
        <v-form class="login-form compliment full-width px-3 py-1 px-sm-4 py-sm-2 px-md-8 py-md-3 px-lg-14 py-lg-4" ref="form" @submit.prevent="adminLogin" v-model="formIsValid">
            <div v-if="formLoading" class="loading-overlay">
                <v-progress-circular class="smoke--text" indeterminate></v-progress-circular>
            </div>

            <div class="form-input-wrapper">
              <label for="login-form-email">Email</label>
              <div class="input-control">
                <ValidationProvider v-slot="{errors, valid}" rules="required|email">
                    <input v-model="email" :class="{'input-unvalidated': !valid && errors.length > 0}" type="text" required inputmode="text" name="Email" id="login-form-email" placeholder="admin@example.com">
                    <v-icon class="input-icon" :class="{'red--text': !valid && errors.length > 0}" size="20px">mdi-email</v-icon>
                    <span v-for="(error, index) in errors" :key="index" class="input-error font-weight-bold warning--text">{{error}}</span>
                </ValidationProvider>
              </div>
            </div>

            <div class="form-input-wrapper">
              <label for="login-form-email">Password</label>
              <div class="input-control">
                <ValidationProvider v-slot="{errors, valid}" rules="required">
                    <input v-model="password" :class="{'input-unvalidated': !valid && errors.length > 0}" type="password" required inputmode="text" placeholder="Password" name="Password" id="login-form-password" @keypress.enter="adminLogin()">
                    <v-icon class="input-icon" :class="{'red--text': !valid && errors.length > 0}" size="20px">mdi-lock</v-icon>
                    <span v-for="(error, index) in errors" :key="index" class="input-error font-weight-bold warning--text">{{error}}</span>
                </ValidationProvider>
              </div>
            </div>

              
            <v-card-actions>
              <div class="submit-button full-width form-input-wrapper">
                  <input type="submit" value="LOGIN" :disabled="!valid" class="full-width smoke--text font-weight-bold" :class="[valid ? 'primary' : 'grey lighten-2 grey--text text--darken-4 cursor--not-allowed']">
              </div>
            </v-card-actions>
          
        </v-form>
      </ValidationObserver>

    </v-col>
</v-row>
</template>

<script>
import firebase from "../../firebaseConfig.js";
import { signInWithEmailAndPassword } from "firebase/auth";
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate';
import { required, email} from 'vee-validate/dist/rules';

setInteractionMode('eager');
extend('required', {
    ...required,
    message: 'This field is required.'
})
extend('email', {
  ...email,
  message: 'Email must be valid.',
})
export default {
    name: "Login",
    metaInfo: {
        title: 'Login | Dillan Johnson'
    },
    components: {
      ValidationProvider,
      ValidationObserver
    },
    data() {
        return {
            email: "",
            password: "",
            emailRules: [
                (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                "E-mail must be valid",
            ],
            formIsValid: false,
            formLoading: false,
            errorText: "",
        }
    },
    methods: {
        async adminLogin(){
            this.formLoading = true;
            const valid = await this.$refs.formObserver.validate();
            if(!valid){
              this.formLoading = false;
              return;
            }
            signInWithEmailAndPassword(firebase.auth, this.email, this.password)
            .then((data) => {
              this.formLoading = false;
              console.log(this.$refs.formObserver)
              if(this.$refs.formObserver){
                this.$refs.formObserver.reset();
              }
              this.email = "";
              this.password = "";
              if(data.user.uid === process.env.VUE_APP_ADMIN_ID){
                // this.$store.commit('setAdmin', true);
                this.$store.commit( 'alertUser', { show: true, message: "Welcome, Dillan.", isGood: true, } );
                this.$router.replace({ path: "/admin" });
              }
            })
            .catch((err) => {
                this.errorText = `${this.setHintText(err.code)}`;
                console.log("error too", err)
                this.$store.commit( 'alertUser', { show: true, message: this.errorText, isGood: false, } );
                this.formLoading = false;
            });
        },
        setHintText: function(error) {
            if ( error == 'auth/wrong-password' || error == 'auth/invalid-email' || error == 'auth/user-not-found'){
                return "Invalid Credentials. Please Check and Try Again.";
            } 
        }
    },
}
</script>
<style scoped lang="scss">
$icon-size: 40px;

.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(43, 43, 43, 0.8);
    border-radius: 10px;
    z-index: 3;
}

.login-form {
    border-radius: 10px;
    box-shadow: 1px 1px 14px var(--v-secondary-darken1);
    position: relative;
    input,textarea {
        width: 100%;
        background-color: var(--v-compliment-darken1);
        color: var(--v-smoke-lighten1);
        padding: .8em 1em .8em 1em;
        border-radius: 10px;
        font-size: .9rem;
        border: 1px solid transparent;
        &:focus{
            // border-inline: 5px 5px solid var(--v-primary-base);
            border: 1px solid var(--v-primary-base);
            box-shadow: 1px 1px 14px var(--v-accent-base);
            outline: none !important;
            transition: border 0.3s ease-in-out;
            color: var(--v-primary-base) !important;
            &::placeholder{
                color: var(--v-primary-base) !important;
                transition: border 0.3s ease-in-out;
            }
        }
        &::placeholder {
            color: var(--v-smoke-darken3);
        }
    }
    input {
        padding-left: $icon-size;
    }
    .input-validated {
        border: 1px solid var(--v-success-base);
    }
    .input-unvalidated {
        border: 1px solid var(--v-warning-base);

    }
    input[type=submit]{
        padding-left: 0;
        padding-right: 0;
    }
    textarea{
        resize: none;
        min-height: 120px;
        padding-left: $icon-size;
    }
    label {
        font-size: .8rem;
        font-weight: 600;
    }
}

.form-input-wrapper {
    margin-bottom: 1rem;
}

.input-control {
    position: relative;
    .input-icon {
        color: var(--v-smoke-darken3);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: $icon-size;
    }
    input:focus+.input-icon{
        color: var(--v-primary-base) !important;
        transition: all 0.3s ease-in-out;

    }
    #message-icon{
        top: 12%;
        transform: translateY(-12%);
    }
}

.input-error {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: .7rem;
}
  
</style>